enum AuthRoutes {
    cabinet = "/cabinet",
}

enum NonAuthRoutes {
    home = "/",
    auth = "/auth",
    signIn = "/auth/signin",
    signUp = "/auth/signup",
    forgotPassword = "/auth/forgot-password",
    newPassword = "/auth/new-password",
    passwordReset = "/auth/reset-password",
    performance = "/performance",
    plans = "/plans",
    accounts = "/accounts",
    community = "/community",
    settings = "/settings",
    emailConfirmation= "/email-confirmation",
}

export { AuthRoutes, NonAuthRoutes };
