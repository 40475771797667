import { action, makeAutoObservable } from "mobx";

class UIStore {
    successAlertContent = '';
    errorAlertContent = '';
    alertContentTime = 20000;
    timerSuccessAlert: any = null;
    timerErrorAlert: any = null;
    mediaQuery = "(max-width: 668px)";
    mobileIs: boolean = window.matchMedia(this.mediaQuery).matches;
    isTouchDevice: boolean = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0);
    popupActive = false;
    buttonState = "plans";

    constructor() {
        makeAutoObservable(this);
    }

    @action changeHeaderButton = (value: string) => {
        this.buttonState = value;
    }

    @action setMobileIs = (value: boolean) => {
        this.mobileIs = value;
    }

    @action setDevices = (value: boolean) => {
        this.isTouchDevice = value;
    }

    @action openPopup = () => {
        this.popupActive = true;
    }

    @action closePopup = () => {
        this.popupActive = false;
    }

    @action setSuccessAlertContent = (value: string, doNotClose = false) => {
        if (this.timerSuccessAlert) {
            clearTimeout(this.timerSuccessAlert);
        }

        this.successAlertContent = value;

        if (!doNotClose) {
            this.timerSuccessAlert = setTimeout(() => {
                this.setSuccessAlertContent('', true);
            }, 4500);
        }
    }

    @action setErrorAlertContent = (value: string, doNotClose = false) => {
        if (this.timerErrorAlert) {
            clearTimeout(this.timerErrorAlert);
        }

        this.errorAlertContent = value;

        if (!doNotClose) {
            this.timerErrorAlert = setTimeout(() => {
                this.setErrorAlertContent('', true);
            }, 3000);
        }
    }

    @action setErrorAlertContentTime = (value: string, time: number) => {
        if (this.timerErrorAlert) {
            clearTimeout(this.timerErrorAlert);
        }

        this.errorAlertContent = value;

        if (time) {
            this.timerErrorAlert = setTimeout(() => {
                this.setErrorAlertContentTime('', 0);
            }, time);
        }
    }

}

const UiStore = new UIStore();

export default UiStore;
