// @ts-nocheck
import * as React from "react";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
// import Badge from "@mui/material/Badge";
// import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
// import { stringAvatar } from "../../helpers/helpers";
import { AppBar, Avatar, Box, Button, Drawer, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar } from "@mui/material";
import { NonAuthRoutes } from "../../routes/routes";
import useMediaQuery from "@mui/material/useMediaQuery";
import DataStore from "../../store/DataStore";

import st from "./Header.module.scss";
import logo from "../../assets/images/logo.svg";
import { ReactComponent as CommunityIcon } from "../../assets/images/icons/community.svg";
import { ReactComponent as DashboardIcon } from "../../assets/images/icons/dashboard.svg";
import { ReactComponent as ExchangesIcon } from "../../assets/images/icons/exchanges.svg";
import { ReactComponent as InvestmentIcon } from "../../assets/images/icons/investment.svg";
import { ReactComponent as LogoutIcon } from "../../assets/images/icons/logout.svg";
import { ReactComponent as NetWrothIcon } from "../../assets/images/icons/net-wroth.svg";
import { ReactComponent as SettingsIcon } from "../../assets/images/icons/settings.svg";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link, NavLink, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { defaultTheme } from "../../assets/themes/primaryMuiTheme";
import { observer } from "mobx-react";
import UIStore from "../../store/UIStore";
import {getData, postData} from "../../api/api";

const menu = [
    {
        icon: <DashboardIcon />,
        link: NonAuthRoutes.home,
        name: "Dashboard",
    },
    {
        icon: <NetWrothIcon />,
        link: NonAuthRoutes.performance,
        name: "Performance",
    },
    {
        icon: <ExchangesIcon />,
        link: NonAuthRoutes.accounts,
        name: "Accounts",
    },
    {
        icon: <InvestmentIcon />,
        link: NonAuthRoutes.plans,
        name: "Plans",
    },
    {
        icon: <CommunityIcon />,
        link: NonAuthRoutes.community,
        disabled: true,
        name: "Community",
    },
    {
        icon: <SettingsIcon />,
        link: NonAuthRoutes.settings,
        name: "Settings",
    },
];

interface Props {
    window?: () => Window;
}

const buttonList: any = {
    accounts: {
        name: "Accounts",
        link: NonAuthRoutes.accounts,
    },
    accountsInner: {
        name: "Choose inner plans",
        link: `${NonAuthRoutes.accounts}/1`,
    },
    plans: {
        name: "Choose plans",
        link: NonAuthRoutes.accounts,
    },
    plansInner: {
        name: "Choose inner plans",
        link: `${NonAuthRoutes.plans}/1`,
    },
};

const Header: React.FC = observer((props: Props) => {
    const [button, setButton] = React.useState({ name: "", link: "" });
    const { buttonState } = UIStore;

    React.useEffect(() => {
        setButton(buttonList[buttonState]);
    }, [buttonState]);

    const { window } = props;
    const container = window !== undefined ? () => window().document.body : undefined;
    const matches = useMediaQuery(`(min-width: ${defaultTheme.breakpoints.values.sm + 1}px )`);
    const [openMobile, setOpenMobile] = React.useState(false);
    const [open, setOpen] = React.useState(!matches);
    const navigate = useNavigate();

    function handleNavigate() {
        navigate(button.link);
        toggleDrawer();
    }

    const toggleDrawer = () => {
        setOpenMobile(!openMobile);
        setOpen(!open);
    };

    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogoutClick = () => {
        getData("auth/logout")
            .then(({ data }) => {
                DataStore.resetData();
                localStorage.removeItem(process.env.REACT_APP_AT_NAME);
                localStorage.removeItem(process.env.REACT_APP_RT_NAME);

            })
            .catch((errorMessage) => {
                console.log("errorMessage", errorMessage);
                setErrorAlertContentTime(JSON.stringify(errorMessage), alertContentTime);
            });

    };

    const handleGetReferralsClick = () => {
        getData("referral/referrals")
            .then(({ data }) => {
                console.log("data", data);
            })
            .catch((errorMessage) => {
                console.log("errorMessage", errorMessage);
                setErrorAlertContentTime(JSON.stringify(errorMessage), alertContentTime);
            });

    };

    const handleRefreshTokenClick = () => {
        postData("auth/refreshToken", {
            accessToken: localStorage.getItem(process.env.REACT_APP_AT_NAME),
            refreshToken: localStorage.getItem(process.env.REACT_APP_RT_NAME),
        }, "post")
            .then(({ data }) => {
                console.log("data", data);
                localStorage.setItem(process.env.REACT_APP_AT_NAME, data.newAccessToken);
            })
            .catch((errorMessage) => {
                console.log("errorMessage", errorMessage);
                setErrorAlertContentTime(JSON.stringify(errorMessage), alertContentTime);
            });
    }

    const user = {
        avatar: null,
        name: "Dave Dev",
    };

    return (
        <>
            <AppBar position='fixed' className={st.header} color={"transparent"}>
                <Toolbar disableGutters sx={{ width: "100%" }}>
                    <div className={st.header__logo}>
                        <Link to={NonAuthRoutes.home}>
                            <img src={logo} alt='Logo' />
                        </Link>
                    </div>
                    <div className={st.header__burger}>
                        <IconButton onClick={toggleDrawer} aria-label='menu' sx={{ display: { md: "none" } }}>
                            <MenuIcon sx={{ width: "32px", height: "32px" }} />
                        </IconButton>
                    </div>

                    <Button onClick={handleNavigate} variant='contained' sx={{ display: { xs: "none", sm: "inline-flex" }, mr: 5, width: 250 }}>
                        {button.name}
                    </Button>

                    {/*<IconButton color="inherit">
                        <Badge badgeContent={4} color="secondary">
                            <NotificationsNoneIcon />
                        </Badge>
                    </IconButton>*/}

                    <Box sx={{ flexGrow: 0 }} ml={3}>
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            {user.avatar ? (
                                <Avatar alt={user.name} src={user.avatar} className={st.header__avatar} />
                            ) : (
                                // <Avatar {...stringAvatar(user.name)} className={st.header__avatar} />
                                <AccountCircleOutlinedIcon sx={{ color: "#000", width: "44px", height: "44px" }} />
                            )}
                        </IconButton>

                        <Menu
                            sx={{ mt: "45px", ".MuiMenu-paper": { backgroundColor: "#fff" } }}
                            id='menu-appbar'
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem onClick={handleCloseUserMenu}>
                                <Link to={NonAuthRoutes.settings}>
                                    <Typography component='span' color='primary' textAlign='center'>
                                        Settings
                                    </Typography>
                                </Link>
                            </MenuItem>

                            <MenuItem onClick={handleLogoutClick}>
                                <Typography component='span' color='primary' textAlign='center'>
                                    Logout
                                </Typography>
                            </MenuItem>

                            <MenuItem onClick={handleRefreshTokenClick}>
                                <Typography component='span' color='primary' textAlign='center'>
                                    Refresh Access Token
                                </Typography>
                            </MenuItem>

                            <MenuItem onClick={handleGetReferralsClick}>
                                <Typography component='span' color='primary' textAlign='center'>
                                    Get Referrals
                                </Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>

            <Drawer
                container={container}
                variant='temporary'
                open={openMobile}
                onClose={toggleDrawer}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: "block", md: "none" },
                }}
                className={st.sidebar}
            >
                <div className={st.header__burger_close}>
                    <IconButton onClick={toggleDrawer} aria-label='menu' sx={{ display: { md: "none" } }}>
                        <CloseIcon sx={{ width: "32px", height: "32px" }} />
                    </IconButton>
                </div>

                <List component='nav'>
                    {menu.map((item) => {
                        return (
                            <NavLink
                                to={item.link}
                                aria-disabled={item.disabled}
                                key={item.link}
                                className={({ isActive }) => (isActive ? classNames(st.sidebar__link, st.sidebar__link_active) : st.sidebar__link)}
                            >
                                <ListItemButton onClick={toggleDrawer}>
                                    <ListItemIcon sx={{ minWidth: { xs: "35px", lg: "56px" } }}>{item.icon}</ListItemIcon>
                                    <ListItemText primary={`${item.name} ${item.disabled ? "(soon)" : ""}`} />
                                </ListItemButton>
                            </NavLink>
                        );
                    })}
                    <ListItemButton onClick={handleLogoutClick}>
                        <ListItemIcon sx={{ minWidth: { xs: "35px", lg: "56px" } }}>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary='Sign Out' />
                    </ListItemButton>
                </List>

                <Button onClick={handleNavigate} variant='contained' sx={{ mt: 5, pointerEvents: "auto" }}>
                    {button.name}
                </Button>
            </Drawer>

            <Drawer
                variant='permanent'
                sx={{
                    display: { xs: "none", md: "block" },
                }}
                open
                className={st.sidebar}
            >
                <List component='nav'>
                    {menu.map((item) => {
                        return (
                            <NavLink
                                to={item.link}
                                aria-disabled={item.disabled}
                                key={item.link}
                                className={({ isActive }) => (isActive ? classNames(st.sidebar__link, st.sidebar__link_active) : st.sidebar__link)}
                            >
                                <ListItemButton>
                                    <ListItemIcon sx={{ minWidth: { sm: "50px", md: "35px", lg: "56px" } }}>{item.icon}</ListItemIcon>
                                    <ListItemText primary={`${item.name} ${item.disabled ? "(soon)" : ""}`} />
                                </ListItemButton>
                            </NavLink>
                        );
                    })}
                    <ListItemButton onClick={handleLogoutClick}>
                        <ListItemIcon sx={{ minWidth: { sm: "50px", md: "35px", lg: "56px" } }}>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary='Sign Out' />
                    </ListItemButton>
                </List>
            </Drawer>
        </>
    );
});

export default Header;
