import * as React from 'react';
import { observer } from 'mobx-react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { NonAuthRoutes } from '../../../routes/routes';
import { Link, useLocation } from 'react-router-dom';
import {getData} from "../../../api/api";
import {useState} from "react";

const EmailConfirmation = () => {
    const query = new URLSearchParams(useLocation().search);
    const queryToken = query?.get('confirmationCode');
    const [emailConfirmationSubmitError, setEmailConfirmationSubmitError] = useState('');
    const [isEmailConfirmationSubmission, setIsEmailConfirmationSubmission] = useState(true);
    const [isEmailConfirmationSubmissionIsSync, setIsEmailConfirmationSubmissionIsSync] = useState(false);

    React.useEffect(() => {
        getData(`user/verifyEmail/${queryToken}`)
            .then(({data}) => {
                if (data.message === "Success") {
                    setIsEmailConfirmationSubmission(false);
                    setIsEmailConfirmationSubmissionIsSync(true);
                }
            })
            .catch((error) => {
                console.log('error', error)
                setEmailConfirmationSubmitError(error);
                setIsEmailConfirmationSubmission(true);
                setIsEmailConfirmationSubmissionIsSync(true);
            });
    }, [queryToken]);

    return (
        <Grid container spacing={6}>
            {isEmailConfirmationSubmission ? (
                <>
                    {isEmailConfirmationSubmissionIsSync && (
                        <Grid item xs={12}>
                            <Typography variant="h6" mb={4} className="error">
                                {emailConfirmationSubmitError.length < 1
                                    ? 'Token is not verifed'
                                    : emailConfirmationSubmitError}
                            </Typography>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Link to={NonAuthRoutes.signIn}>
                            <Button variant="contained" color="primary" autoFocus sx={{width: '100%'}}>
                                Ok
                            </Button>
                        </Link>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid item xs={12}>
                        <Typography component="h1" variant="h3" sx={{m: 0}} gutterBottom>Success!</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography component="p" variant="body1">
                            New account has been created successfully! Use your new account to Sign in.
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Link to={NonAuthRoutes.signIn}>
                            <Button variant="contained" color="primary" autoFocus sx={{width: '100%'}}>
                                Sign in
                            </Button>
                        </Link>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default observer(EmailConfirmation);
