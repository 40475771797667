// @ts-nocheck
import React from "react";
import classNames from "classnames";

import st from "./TotalInfo.module.scss";

import { ReactComponent as RisingIcon } from "../../assets/images/icons/rising-chart.svg";
import { ReactComponent as FallingIcon } from "../../assets/images/icons/falling-chart.svg";
import { ReactComponent as ArrowUpIcon } from "../../assets/images/icons/arrow-to-up.svg";
import { ReactComponent as ArrowDownIcon } from "../../assets/images/icons/arrow-to-down.svg";

type TotalInfoProps = {
    title: string;
    balance?: string;
    number?: string;
    className?: string;
    toUp?: boolean;
    toDown?: boolean;
    noIcon?: boolean;
};

const TotalInfo: React.FC<TotalInfoProps> = ({
   title,
   balance,
   number,
   className,
   toUp,
   toDown,
   noIcon = false,
}) => {
    return (
        <div className={classNames(st.total, className)}>
            {!noIcon ? (
                toDown ?
                    <FallingIcon className={st.total__icon} />
                    :
                    <RisingIcon className={st.total__icon} />

            ) : (
                ""
            )}
            <div className={st.total__small}>{title}</div>
            <div className={st.total__big}>{balance}</div>
            <div className={classNames(st.total__number, (toUp ? st.total__number_up : (toDown ? st.total__number_down : '')))}>
                {
                    toUp ? (
                        <ArrowUpIcon className={st.total__arrow} />
                    ) : (
                        toDown ?
                            <ArrowDownIcon className={st.total__arrow} />
                            :
                            ""
                    )
                }
                <span>{toUp ? '+' : ''}{number}</span>
            </div>
        </div>
    );
};

export default TotalInfo;
