// @ts-nocheck
import { Box, Grid } from "@mui/material";
import TotalInfo from "../../components/TotalInfo/TotalInfo";
import st from "./Performance.module.scss";
import classNames from "classnames";
import ChartLayout from "../../components/Chart/Layout";
import { ReactComponent as ArrowUpIcon } from "../../assets/images/icons/arrow-to-up.svg";
import { ReactComponent as DollarIcon } from "../../assets/images/icons/dollar.svg";
import { ReactComponent as TableIcon } from "../../assets/images/icons/table.svg";
import TableLayout from "../../components/Table/TableLayout";
import TableGrid from "../../components/Table/Table";
import React from "react";
import BarCharts from "../../components/Chart/Bar";
// import DataStore from "../../store/DataStore";
import { observer } from "mobx-react";
import UiStore from "../../store/UIStore";
// import { ReactComponent as ArrowDownIcon } from "../../assets/images/icons/arrow-to-down.svg";
// import { neatNumber } from "../../helpers/helpers";
// import NumberFormat from "react-number-format";

// const formatNumber = (value) => {
//     return <NumberFormat value={value} decimalScale={2} defaultValue={0} thousandSeparator displayType={"text"} prefix='$' />;
// };

const Performance: React.FC = observer(() => {
    const { changeHeaderButton } = UiStore;

    React.useEffect(() => {
        changeHeaderButton("plans");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid component='section' container spacing={6}>
            <Grid item xs={12} md={6}>
                <Grid component='section' container className={st.section}>
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={1.5}>
                            <Grid item xs={12} sm='auto' md={12} className={st.total__item}>
                                <div className={st.total__big}>$1280</div>
                                <div className={st.total__small}>Total</div>
                            </Grid>

                            <Grid item xs={12} sm='auto' md={12} className={st.total__item}>
                                <div className={st.total__big}>$280</div>
                                <div className={st.total__small}>Last 30 Days</div>
                            </Grid>

                            <Grid item xs={12} sm='auto' md={12}>
                                <div className={classNames(st.total__number, st.total__number_up)}>
                                    <ArrowUpIcon className={st.total__arrow} />
                                    <span>+12.4%</span>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <div className={st.total__title}>Your Total Profit</div>
                        <div className={st.total__text}>You are doing great job this month! Keep going on 🔥</div>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={6} md={3}>
                <TotalInfo title='Daily' balance='$1,428' number='-7.6%' />
            </Grid>

            <Grid item xs={6} md={3}>
                <TotalInfo className={st.total__monthly} title='Monthly' balance='$158.3' number='2.4%' toUp />
            </Grid>

            <Grid item xs={12} pt={10}>
                <ChartLayout className={st.bordered} title='Monthly Performance' icon={<DollarIcon />} >
                    <Box className={st.barChart}>
                        {/* <BarCharts data={DataStore.userMonthlyProfits} /> */}
                        <BarCharts />
                    </Box>
                </ChartLayout>
            </Grid>

            <Grid item xs={12} pt={10}>
                <TableLayout className={st.bordered} title='Opened Deals' icon={<TableIcon />}>
                    {/* <TableGrid data={DataStore.userDeals} /> */}
                    <TableGrid />
                </TableLayout>
            </Grid>
        </Grid>
    );
});

export default Performance;
