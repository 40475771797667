import React from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

// styles
import st from "./Accounts.module.scss";

const AccountsLayout: React.FC = (() => {
    return (
        <Box component="section" className={st.investments}>
            <Outlet />
        </Box>
    );
});

export default AccountsLayout;
