import * as React from "react";
import { observer } from "mobx-react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Field, FormikProvider, useFormik } from "formik";
import { NonAuthRoutes } from "../../../routes/routes";
import { Link, useLocation, useNavigate } from "react-router-dom";
import st from "../Auth.module.scss";
import UIStore from "../../../store/UIStore";
import { postData } from "../../../api/api";

const NewPassword = () => {
    const navigate = useNavigate();
    const { alertContentTime, setErrorAlertContent, setErrorAlertContentTime, setSuccessAlertContent } = UIStore;

    const query = new URLSearchParams(useLocation().search);
    const queryToken = query?.get("reset_token");
    const queryUserID = query?.get("user_id");

    const validate = (values: any) => {
        const errors: any = {};

        if (!values.new_password) {
            errors.new_password = "Required";
        }

        if (values.new_password && values.new_password.length < 6) {
            errors.new_password = "At least 6 symbols";
        }

        if (values.new_password && values.new_password.length > 5 && values.confirm_password && values.confirm_password !== values.new_password) {
            errors.new_password = "Password does not match";
        }

        if (!values.confirm_password) {
            errors.confirm_password = "Required";
        }

        if (values.confirm_password && values.confirm_password !== values.new_password) {
            errors.confirm_password = "Password does not match";
        }

        return errors;
    };

    const form = useFormik({
        initialValues: {
            reset_token: queryToken,
            user_id: queryUserID,
            new_password: "",
            confirm_password: "",
        },
        validateOnBlur: false,
        validateOnChange: false,
        validate: validate,
        onSubmit: (values) => {
            setErrorAlertContent("", true);
            postData("reset_password", values, "post")
                .then(() => {
                    setSuccessAlertContent("Your password has been changed successfully");
                    navigate(NonAuthRoutes.signIn);
                })
                .catch((errorMessage: any) => {
                    setErrorAlertContentTime(errorMessage, alertContentTime);
                });
        },
    });

    return (
        <>
            <Typography component='h1' variant='h3' mb={2}>
                New Password
            </Typography>
            <FormikProvider value={form}>
                <Box component='form' noValidate onSubmit={form.handleSubmit} sx={{ mt: 1, width: "100%" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Field
                                name='new_password'
                                children={(fieldProps: any) => (
                                    <TextField
                                        {...fieldProps.field}
                                        type='password'
                                        fullWidth
                                        label='Password'
                                        error={form.touched.new_password && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                        helperText={form.touched.new_password && !form.isValid && fieldProps.meta.error}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Field
                                name='confirm_password'
                                children={(fieldProps: any) => (
                                    <TextField
                                        {...fieldProps.field}
                                        type='password'
                                        fullWidth
                                        label='Confirm password'
                                        error={form.touched.confirm_password && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                        helperText={form.touched.confirm_password && !form.isValid && fieldProps.meta.error}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
                        Save
                    </Button>
                    <Grid container>
                        <Grid item textAlign='center' xs>
                            <Typography variant='body2'>
                                Do you remember it?{" "}
                                <Link to={NonAuthRoutes.signIn} className={st.link}>
                                    {"Sign In"}
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                {/* <pre style={{color: "saddlebrown", }}>{// @ts-ignore
                    JSON.stringify(form.values, 0, 2)}</pre> */}
            </FormikProvider>
        </>
    );
};

export default observer(NewPassword);
