// @ts-nocheck
import React from "react";
import st from "./Table.module.scss";
import { Button, TableContainer } from "@mui/material";
import classNames from "classnames";

function createData(name: string, balance: string, amout: string, change: string, more: React.ReactNode, sign?: boolean) {
    return { name, balance, amout, change, more, sign };
}

const rows = [
    createData(
        "EUR / USD",
        "$128,2812.92",
        "$12,2812.92",
        "+12,4%",
        <Button size='small' variant='outlined'>
            Choose
        </Button>,
        true
    ),
    createData(
        "USD / EUR",
        "$128,2812.92",
        "$12,2812.92",
        "-12,4%",
        <Button size='small' variant='outlined'>
            Choose
        </Button>
    ),
    createData(
        "USD / GBP",
        "$128,2812.92",
        "$12,2812.92",
        "+12,4%",
        <Button size='small' variant='outlined'>
            Choose
        </Button>,
        true
    ),
    createData(
        "GBP / EUR",
        "$128,2812.92",
        "$12,2812.92",
        "-12,4%",
        <Button size='small' variant='outlined'>
            Choose
        </Button>
    ),
    createData(
        "GBP / USD",
        "$128,2812.92",
        "$12,2812.92",
        "+12,4%",
        <Button size='small' variant='outlined'>
            Choose
        </Button>,
        true
    ),
];

const TableGrid: React.FC = ({ data = [] }) => {
    return (
        <TableContainer>
            <div aria-label='caption table' className={st.table}>
                <div className={st.head}>
                    <div className={st.th}>Type of invest</div>
                    <div className={st.th}>Balance</div>
                    <div className={st.th}>Change Amount</div>
                    <div className={st.th}>Change, %</div>
                    <div className={st.th}>More</div>
                </div>

                <div className={st.body}>
                    {rows.map((row, index) => (
                        <div key={"row" + index} className={st.tr}>
                            <div className={st.td}>{row.name}</div>

                            <div className={st.td}>{row.balance}</div>

                            <div className={classNames(st.td, row.sign ? st.td_green : st.td_red)}>{row.amout}</div>

                            <div className={classNames(st.td, row.sign ? st.td_green : st.td_red)}>{row.change}</div>

                            <div className={st.td}>
                                <Button size='small' variant='outlined'>
                                    Choose
                                </Button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </TableContainer>
    );
};

export default TableGrid;
