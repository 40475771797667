import React from "react";
import { Link, Grid, Typography, Button, Autocomplete, TextField } from "@mui/material";
import st from "./Connect.module.scss";
import UiStore from "../../../store/UIStore";

const opt = [
    { label: "Select your account", value: 0 },
    { label: "I", value: 1 },
    { label: "II", value: 2 },
];

const Connect: React.FC = () => {
    const { changeHeaderButton } = UiStore;

    React.useEffect(() => {
        changeHeaderButton("plans");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid component='section' container spacing={6}>
            <Grid item xs={12}>
                <Grid container className={st.box__item} alignItems="center">
                    <Grid item xs={12} md={6} lg={4} pr={{ xs: 0, md: 2 }}>
                        <Typography variant='h2' className={st.deposit__title} mb={4}>
                            Connect Account
                        </Typography>
                        <Grid container spacing={2} mb={4}>
                            <Grid item xs={12} md={6} lg={8}>
                                Selected Plan:
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                Fee
                            </Grid>
                            <Grid item xs={12} md={6} lg={8}>
                                <Typography variant='h5'>Plan #1</Typography>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <Typography variant='h5'>20%</Typography>
                            </Grid>
                        </Grid>
                        <Typography variant='body2' fontWeight={500} mb={1}>
                            SELECT ACCOUNT
                        </Typography>
                        <Autocomplete
                            options={opt}
                            sx={{ width: "100%", mb: 4 }}
                            renderInput={(params) => <TextField {...params} label='Select your account' />}
                        />

                        <Button variant='contained' fullWidth>
                            Start Trading
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6} ml='auto' mt={{ xs: 4, md: 0 }}>
                        <div className={st.box__text}>
                            🧾 At the end of every 30 days since you have started trading with us, we are issuing an invoice for the performance fee.
                            You have 3 days to pay the invoice and if the payment has not been received by then, the trading will stop automatically.
                            <br />
                            <br />
                            🙋 You have any questions, please as your advisor or <Link href='/#'>contact us</Link>.
                            <br />
                            <br />
                            ⚠️ Please consider that trading is at your own risk. Forex is considered a high risk and reward investment. By clicking
                            the button, you agree with our <Link href='/#'>Terms and Conditions</Link>.
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <Grid container spacing={2} alignItems='center'>
                    <Grid item xs={4}>
                        <Typography variant='h4'>Status:</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <svg width='31' height='32' viewBox='0 0 31 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <circle cx='15.375' cy='15.7778' r='15.375' fill='#09EAB4' />
                            <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M21.6984 13.2694L13.9432 21.2144C13.4437 21.7261 12.6351 21.7261 12.1369 21.2144L8.89733 17.8969C8.39913 17.3852 8.39913 16.5568 8.89733 16.0451C9.3968 15.5347 10.2054 15.5347 10.7036 16.0451L13.04 18.4387L19.8921 11.4189C20.3903 10.9072 21.1989 10.9072 21.6984 11.4189C22.1979 11.9293 22.1979 12.7577 21.6984 13.2694Z'
                                fill='black'
                            />
                        </svg>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant='h4'>Trading Started</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Connect;
