import {
    Link,
    Box,
    Button,
    Grid,
    TextField,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    FormLabel,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    InputAdornment,
    Input,
    InputLabel,
    IconButton,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React from "react";
import { defaultTheme } from "../../../assets/themes/primaryMuiTheme";
import st from "./Payment.module.scss";
import NumberFormat from "react-number-format";
import { IMaskInput } from "react-imask";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TableGrid from "../../../components/Table/Table";
import { ReactComponent as TableIcon } from "../../../assets/images/icons/table.svg";
import TableLayout from "../../../components/Table/TableLayout";
import UiStore from "../../../store/UIStore";

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

// @ts-ignore
const NumberFormatCustom = React.forwardRef<NumberFormat, CustomProps>(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(amount) => {
                onChange({
                    target: {
                        name: props.name,
                        value: amount.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix='$'
        />
    );
});

const TextMaskCard = React.forwardRef<HTMLElement, CustomProps>(function TextMaskCard(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask='0000 0000 0000 0000'
            // @ts-ignore
            inputRef={ref}
            onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

const TextMaskDate = React.forwardRef<HTMLElement, CustomProps>(function TextMaskDate(props, ref) {
    const { onChange, ...other } = props;
    return (
        // @ts-ignore
        <IMaskInput {...other} mask='00/00' inputRef={ref} onAccept={(value: any) => onChange({ target: { name: props.name, value } })} overwrite />
    );
});

const TextMaskCVV = React.forwardRef<HTMLElement, CustomProps>(function TextMaskCVV(props, ref) {
    const { onChange, ...other } = props;
    // @ts-ignore
    return <IMaskInput {...other} mask='000' inputRef={ref} onAccept={(value: any) => onChange({ target: { name: props.name, value } })} overwrite />;
});

const Payment: React.FC = () => {
    const { changeHeaderButton } = UiStore;

    React.useEffect(() => {
        changeHeaderButton("accounts");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [value, setValue] = React.useState("1");

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log(data);
    };

    const [amount, setAmount] = React.useState<string>("");

    const handleInputAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAmount(event.target.value);
    };

    const [copyText, setCopyText] = React.useState("");

    const handlerInputWallet = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCopyText(event.target.value);
    };

    const handleCopyClick = async () => {
        await navigator.clipboard.writeText(copyText);
    };

    const [cardNumber, setCardNumber] = React.useState<string>("");

    const handleCardNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCardNumber(event.target.value);
    };

    const [cardDate, setCardDate] = React.useState<string>("");

    const handleCardDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCardDate(event.target.value);
    };

    const [CVV, setCVV] = React.useState<string>("");

    const handleCVV = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCVV(event.target.value);
    };

    const [referralCode, setReferralCode] = React.useState<string>("");

    const handlerInputReferral = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReferralCode(event.target.value);
    };

    return (
        <Grid component='section' container spacing={6}>
            <Grid item xs={12}>
                <div className={st.box__item}>
                    <div className={st.box__title}>Payment</div>
                    <div className={st.box__text}>
                        Our platform charges a standard one time entry fee in order to help the platform sustain its cost and continuously improve.
                        This fee is subject to different rules, periods or discounts.
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography color='primary' component='h1' variant='h2' className={st.deposit__title}>
                    Payment Method
                </Typography>
                <Grid item xs={12} md={10} lg={8} mt={3}>
                    <Box component='form' noValidate onSubmit={handleSubmit}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label='Amount'
                                value={amount}
                                onChange={handleInputAmount}
                                name='amount'
                                id='input-amount'
                                InputProps={{
                                    inputComponent: NumberFormatCustom as any,
                                }}
                                variant='standard'
                            />
                        </Grid>

                        <Grid item xs={12} mt={4}>
                            {/* <FormLabel id="type-tabs">Type</FormLabel> */}
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                    <TabList onChange={handleTabChange} aria-label='Tabs for type'>
                                        <Tab label='FIAT' value='1' />
                                        <Tab label='Crypto' value='2' />
                                    </TabList>
                                </Box>
                                <TabPanel value='1' sx={{ p: 0 }}>
                                    <FormControl sx={{ mt: 3 }}>
                                        <FormLabel id='type-currency'>Currency</FormLabel>
                                        <RadioGroup row aria-labelledby='type-currency' name='type-currency'>
                                            <FormControlLabel value='EUR' control={<Radio />} label='EUR' />
                                            <FormControlLabel value='USD' control={<Radio />} label='USD' />
                                            <FormControlLabel value='GBP' control={<Radio />} label='GBP' />
                                        </RadioGroup>
                                    </FormControl>

                                    <Grid container spacing={1} mt={2}>
                                        <Grid item xs={8}>
                                            <FormControl variant='standard' sx={{ width: "100%" }}>
                                                <InputLabel htmlFor='card-number'>Card number</InputLabel>
                                                <Input
                                                    value={cardNumber}
                                                    onChange={handleCardNumber}
                                                    name='card-number'
                                                    id='card-number'
                                                    fullWidth
                                                    inputComponent={TextMaskCard as any}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormControl variant='standard'>
                                                <InputLabel htmlFor='card-date'>EXP. Date</InputLabel>
                                                <Input
                                                    value={cardDate}
                                                    onChange={handleCardDate}
                                                    name='card-date'
                                                    id='card-date'
                                                    fullWidth
                                                    inputComponent={TextMaskDate as any}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormControl variant='standard'>
                                                <InputLabel htmlFor='card-cvv'>CVV</InputLabel>
                                                <Input
                                                    value={CVV}
                                                    onChange={handleCVV}
                                                    name='card-cvv'
                                                    id='card-cvv'
                                                    fullWidth
                                                    inputComponent={TextMaskCVV as any}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <FormControl sx={{ mt: 3, mb: 3, width: "100%" }} variant='standard'>
                                        <InputLabel htmlFor='cardholder-name'>Cardholder name</InputLabel>
                                        <Input fullWidth id='cardholder-name' type='text' name='cardholder-name' />
                                    </FormControl>
                                </TabPanel>

                                <TabPanel value='2' sx={{ p: 0 }}>
                                    <FormControl sx={{ mt: 3 }}>
                                        <FormLabel id='type-amount'>Amount</FormLabel>
                                        <RadioGroup row aria-labelledby='type-amount' name='type-amount'>
                                            <FormControlLabel value='USDT' control={<Radio />} label='USDT' />
                                            <FormControlLabel value='BUSD' control={<Radio />} label='BUSD' />
                                            <FormControlLabel value='USDC' control={<Radio />} label='USDC' />
                                        </RadioGroup>
                                    </FormControl>

                                    <FormControl sx={{ mt: 3 }}>
                                        <FormLabel id='type-network'>Network</FormLabel>
                                        <RadioGroup row aria-labelledby='type-network' name='type-network'>
                                            <FormControlLabel value='ERC20' control={<Radio />} label='ERC20' />
                                            <FormControlLabel value='BSC' control={<Radio />} label='BSC' />
                                            <FormControlLabel value='TRC20' control={<Radio />} label='TRC20' />
                                            <FormControlLabel value='Solana' control={<Radio />} label='Solana' />
                                            <FormControlLabel value='Polygon' control={<Radio />} label='Polygon' />
                                        </RadioGroup>
                                    </FormControl>

                                    <FormControl sx={{ mt: 3, mb: 3, width: "100%" }} variant='standard'>
                                        <InputLabel htmlFor='wallet-address'>Wallet address</InputLabel>
                                        <Input
                                            value={copyText}
                                            onChange={handlerInputWallet}
                                            fullWidth
                                            id='wallet-address'
                                            type='text'
                                            name='wallet-address'
                                            endAdornment={
                                                <InputAdornment position='end'>
                                                    <IconButton aria-label='wallet address copy' onClick={handleCopyClick} edge='end'>
                                                        <ContentCopyIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </TabPanel>
                            </TabContext>
                        </Grid>

                        <Button type='submit' size='large' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
                            Payment funds
                        </Button>
                        <Grid container>
                            <Grid item textAlign='center' xs>
                                By depositing funds into our investment fund, you agree to our{" "}
                                <Link href='#' underline='always' color='primary'>
                                    Risk & Disclosure Agreement
                                </Link>
                                .
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography color='primary' component='h2' variant='h2' className={st.deposit__title}>
                    Transaction status:
                </Typography>
                <Grid item xs={12} mt={3} mb={3}>
                    <FormControl sx={{ mt: 3, mb: 3, width: "100%" }} variant='standard'>
                        <InputLabel htmlFor='referral-code'>Referral code</InputLabel>
                        <Input value={referralCode} onChange={handlerInputReferral} fullWidth id='referral-code' type='text' name='referral-code' />
                    </FormControl>

                    <Button type='submit' size='large' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
                        Apply Code
                    </Button>
                </Grid>
                <Typography color='primary' component='h2' variant='h2' className={st.deposit__title}>
                    Transaction status:
                </Typography>
                <Grid item xs={12} mt={3}>
                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell component='th'>
                                        <Typography sx={{ opacity: 0.6 }}>Date</Typography>
                                    </TableCell>
                                    <TableCell component='th'>
                                        <Typography sx={{ opacity: 0.6 }}>Status</Typography>
                                    </TableCell>
                                    <TableCell component='th'>
                                        <Typography sx={{ opacity: 0.6 }}>Amount</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>10/05/2022</TableCell>
                                    <TableCell>Pending</TableCell>
                                    <TableCell>
                                        <Typography>$12,2812.92</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>10/05/2022</TableCell>
                                    <TableCell>Completed</TableCell>
                                    <TableCell>
                                        <Typography color={defaultTheme.palette.success.main}>$12,2812.92</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>10/05/2022</TableCell>
                                    <TableCell>Rejected</TableCell>
                                    <TableCell>
                                        <Typography color={defaultTheme.palette.error.main}>$12,2812.92</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Grid item xs={12} mt={5}>
                <TableLayout title='History' icon={<TableIcon />}>
                    <TableGrid />
                </TableLayout>
            </Grid>
        </Grid>
    );
};

export default Payment;
