import * as React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";

import { CssBaseline, ThemeProvider } from "@mui/material";
import primaryMuiTheme from "./assets/themes/primaryMuiTheme";
import ScrollToTop from "./components/ScrollToTop";

const container = document.getElementById("app");
const root = createRoot(container as HTMLDivElement);

root.render(
    <ThemeProvider theme={primaryMuiTheme}>
        <CssBaseline />
        <Router>
            <ScrollToTop />
            <App />
        </Router>
    </ThemeProvider>
);
