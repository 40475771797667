// @ts-nocheck
import { Field, FormikProvider, useFormik } from 'formik';
// import axios from "axios";
import mediaObjectApi from "../../api/mediaObjectApi";
import Box from "@mui/material/Box";
import {
    alpha,
    Avatar,
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    List,
    ListItem,
    // ListItemAvatar,
    ListItemText,
    styled,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
// import { IMaskInput } from "react-imask";
import st from "./Settings.module.scss";
import { ReactComponent as SettingsIcon } from "../../assets/images/icons/settings.svg";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import GroupsIcon from "@mui/icons-material/Groups";
import LinkIcon from "@mui/icons-material/Link";
import BarChartIcon from "@mui/icons-material/BarChart";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import validator from "validator";
import { getData, postData } from "../../api/api";
import DataStore from "../../store/DataStore";
import UIStore from "../../store/UIStore";
import isMobilePhone from "validator/lib/isMobilePhone";
import { observer } from "mobx-react";

/*interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const TextMaskCard = React.forwardRef<HTMLElement, CustomProps>(function TextMaskCard(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="+{0} (000) 000-00-00"
            // @ts-ignore
            inputRef={ref}
            onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});*/

const GreenSwitch = styled(Switch)(({ theme}) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
        color: theme.palette.success.main,
        "&:hover": {
            backgroundColor: alpha(theme.palette.success.main, theme.palette.action.hoverOpacity),
        },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
        backgroundColor: theme.palette.success.main,
    },
}));


const Settings: React.FC = observer(() => {
    const { user } = DataStore;
    const { alertContentTime, setErrorAlertContent, setErrorAlertContentTime, setSuccessAlertContent, changeHeaderButton } = UIStore;
    const [avatar, setAvatar] = useState<any>("");
    const [countries, setCountries] = useState<any>([]);
    const reader = new FileReader();
    const inputEl = React.useRef<any>(null);

    const handleCopyClick = async (e) => {
        await navigator.clipboard.writeText(inputEl?.current?.value);
    };

/*    const [phoneNumber, setPhoneNumber] = React.useState<string>("+");

    const handlePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(event.target.value);
    };*/

    const uploadAvatar = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        if (!target.files) {
            return;
        }

        const file = target.files[0];
        console.log('file', file);
        mediaObjectApi.loadMedia(file, user.id)
            .then((response) => {
                console.log('response', response);
                // if (response.status === 201 && response.data.id && response.data.contentUrl) {
                //     const avatarIri = `api/media_objects/${response.data.id}`;
                //     formPersonal.setFieldValue('avatar', avatarIri);
                //
                //     updateUserObject(
                //         {
                //             profile: {
                //                 id: (formPersonal.values.id ? `api/profiles/${formPersonal.values.id}` : null),
                //                 avatar: avatarIri,
                //             }
                //         });
                // } else {
                //     console.log(response);
                // }
            })
            .catch(error => {
                console.log(error);
                setErrorAlertContent(JSON.stringify(error), true);
            });

        reader.readAsDataURL(file);
        // reader.readAsArrayBuffer(file);

        reader.onload = () => {
            console.log(reader);
            setAvatar(reader.result);

/*            axios({
                method: 'PUT',
                url:  `${axios.defaults.baseURL}/users/${user.id}/info/avatar`,
                data: reader.result,
                headers: {
                    // 'Content-Type': 'multipart/form-data',
                    // 'accept': '*!/!*',
                    'Content-Type': 'image/png',
                    'jwt': localStorage.getItem(process.env.REACT_APP_AT_NAME),
                },
            }).then(({ data }) => {
                console.log('data', data)
                // DataStore.updateUser(values);
                setSuccessAlertContent("Successfully changed");
            })
                .catch((errorMessage) => {
                    console.log('errorMessage', errorMessage)
                        setErrorAlertContentTime(JSON.stringify(errorMessage), alertContentTime);
                    }
            );*/
        };

        reader.onerror = () => {
            console.log(reader.error);
        };
    };

    const validate = (values: any) => {
        const errors: any = {};
        let phone = values.phoneNumber.replace(/^\+/, '');
        phone = phone.replace(/[\s-]/g, '');

        if (!values.firstName.trim()) {
            errors.firstName = "Required";
        }

        if (!values.lastName.trim()) {
            errors.lastName = "Required";
        }

        if (!values.email) {
            errors.email = "Required";
        }

        if (values.email && !validator.isEmail(values.email)) {
            errors.email = "Invalid email";
        }

        if (phone && !isMobilePhone(phone)) {
            errors.phoneNumber = "Invalid phone number";
        }

        if (!values.password) {
            errors.password = "Required";
        }

        if (values.password && values.password.length < 6) {
            errors.password = "At least 6 symbols";
        }

        if (values.password && (values.password.length > 5) && values.confirm_password && (values.confirm_password !== values.password)) {
            errors.password = "Password does not match";
        }

        if (!values.confirm_password) {
            errors.confirm_password = "Required";
        }

        if (values.confirm_password && values.confirm_password !== values.password) {
            errors.confirm_password = "Password does not match";
        }

        return errors;
    }

    const form = useFormik({
        enableReinitialize: true,
        validate: validate,
        initialValues: {
            firstName: user.firstName ?? '',
            lastName: user.lastName ?? '',
            email: user.email ?? '',
            address: user.address ?? '',
            region: user.region ?? '',
            country: user.country ?? '',
            phoneNumber: user.phoneNumber ?? '+',
            referrerCode: user.referrerCode ?? '',
            email_notifications: user.email_notifications ?? false,
            phone_notifications: user.phone_notifications ?? false,
            telegram_notifications: user.telegram_notifications ?? false,
            onboarding_completed: user.onboarding_completed ?? null,
            password: '',
            confirm_password: '',
        },
        onSubmit: (values, { setErrors }) => {
            setSuccessAlertContent('', true);
            setErrorAlertContent('', true);

            postData(`users/${user.id}/info`, values, 'put')
                .then(({ data }) => {
                    DataStore.updateUser(values);
                    setSuccessAlertContent("Successfully changed");
                    if (data?.id) {
                        // DataStore.updateAuthorize(true);
                        // Request do not return user object
                        // DataStore.updateUser(values);
                    } else {
                        console.log('data');
                    }
                })
                .catch((errorMessage) => {
                        setErrorAlertContentTime(JSON.stringify(errorMessage), alertContentTime);
                    }
                );
        },
    });

    React.useEffect(() => {
        let isMounted = true;

        changeHeaderButton("plans");

        if (0) {
            getData('users/countries')
                .then(({ data }) => {
                    if (isMounted) {
                        setCountries(data);
                    }
                })
                .catch(error => console.log({error}));
        }

        return () => { isMounted = false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <Box component="section" className={st.settings__container}>
            <FormikProvider value={form}>
                <Grid component="form" container spacing={10} onSubmit={form.handleSubmit}>
                    {/*<FormikProvider value={form}>*/}
                        {/*<Box component="form" noValidate onSubmit={form.handleSubmit}>*/}
                    <Grid item xs={12} md={6} lg={5}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Typography color="primary" component="h1" variant="h3" className={st.settings__title}>
                                    <SettingsIcon />
                                    <span>Settings</span>
                                </Typography>
                            </Grid>


                            <Grid item xs={12}>
                                <Grid container alignItems="center">
                                    <label htmlFor="button-file" className={st.avatar}>
                                        <input id="button-file" type="file" style={{ display: "none" }} onChange={uploadAvatar} />
                                        <Avatar
                                            src={avatar}
                                            sx={{
                                                width: { xs: "100px", sm: "180px" },
                                                height: { xs: "100px", sm: "180px" },
                                            }}
                                        >
                                            {!avatar ? <AccountCircleOutlinedIcon sx={{ color: "#fff", width: "56px", height: "56px" }} /> : ""}
                                        </Avatar>
                                        <IconButton className={st.avatar__up}>
                                            <ArrowUpwardOutlinedIcon />
                                        </IconButton>
                                    </label>

                                    <Grid item color="primary" alignItems="center" ml={2}>
                                        <Typography variant="h5" color="primary">
                                            Profile Picture
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} lg={6}>
                                        <Field
                                            name="firstName"
                                            children={(fieldProps: any) => (
                                                <TextField
                                                    {...fieldProps.field}

                                                    type="text"
                                                    fullWidth
                                                    label="First Name"
                                                    error={form.touched.firstName && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                    helperText={form.touched.firstName && !form.isValid && fieldProps.meta.error}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={12} lg={6}>
                                        <Field
                                            name="lastName"
                                            children={(fieldProps: any) => (
                                                <TextField
                                                    {...fieldProps.field}

                                                    type="text"
                                                    fullWidth
                                                    label="Last Name"
                                                    error={form.touched.lastName && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                    helperText={form.touched.lastName && !form.isValid && fieldProps.meta.error}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Field
                                            name="email"
                                            children={(fieldProps: any) => (
                                                <TextField
                                                    {...fieldProps.field}

                                                    type="text"
                                                    fullWidth
                                                    label="Email Address"
                                                    error={form.touched.email && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                    helperText={form.touched.email && !form.isValid && fieldProps.meta.error}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={12} lg={6}>
                                        <Field
                                            name="password"
                                            children={(fieldProps: any) => (
                                                <TextField
                                                    {...fieldProps.field}

                                                    type="password"
                                                    fullWidth
                                                    label="Password"
                                                    error={form.touched.password && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                    helperText={form.touched.password && !form.isValid && fieldProps.meta.error}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={12} lg={6}>
                                        <Field
                                            name="confirm_password"
                                            children={(fieldProps: any) => (
                                                <TextField
                                                    {...fieldProps.field}

                                                    type="password"
                                                    fullWidth
                                                    label="Confirm password"
                                                    error={form.touched.confirm_password && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                    helperText={form.touched.confirm_password && !form.isValid && fieldProps.meta.error}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Field
                                            name="address"
                                            children={(fieldProps: any) => (
                                                <TextField
                                                    {...fieldProps.field}

                                                    type="text"
                                                    fullWidth
                                                    label="Address"
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={12} lg={6}>
                                        <Field
                                            name="region"
                                            children={(fieldProps: any) => (
                                                <TextField
                                                    {...fieldProps.field}

                                                    type="text"
                                                    fullWidth
                                                    label="Region"
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={12} lg={6}>
                                        <TextField
                                            value={form.values.country}
                                            onChange={
                                                (e) => {
                                                    // user.country = e.target.value;
                                                    form.setFieldValue('country', e.target.value);
                                                }}
                                            fullWidth
                                            id="country"
                                            name="country"
                                            select
                                            label="Country"
                                            SelectProps={{
                                                native: true,
                                            }}

                                            className={st.select}
                                        >
                                            <option value=""></option>
                                            {countries.map((item, index) => {
                                                return <option key={index} value={item}>{item}</option>;
                                            })}
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12}>
    {/*                                    <InputLabel htmlFor="card-number">Phone number</InputLabel>
                                        <Input
                                            name="phoneNumber"
                                            value={phoneNumber}
                                            onChange={handlePhoneNumber}
                                            fullWidth
                                            inputComponent={TextMaskCard as any}
                                            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                        />*/}
                                        <Field
                                            name="phoneNumber"
                                            children={(fieldProps: any) => (
                                                <TextField
                                                    {...fieldProps.field}

                                                    type="text"
                                                    fullWidth
                                                    label="Phone number"
                                                    error={form.touched.phoneNumber && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                    helperText={form.touched.phoneNumber && !form.isValid && fieldProps.meta.error}
                                                />
                                            )}
                                        />
                                    </Grid>
{/*                                    <Grid item xs={12}>
                                        <Button fullWidth variant="contained">
                                            Connect Telegram
                                        </Button>
                                    </Grid>*/}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={6} lg={5}>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <Typography color="primary" component="h2" variant="h3" className={st.settings__title}>
                                            <NotificationsNoneIcon />
                                            <span>Notifications</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <GreenSwitch
                                                                name="email_notifications"
                                                                checked={form.values.email_notifications}
                                                                onChange={(event: any, newValue: any) => {
                                                                    form.setFieldValue('email_notifications', newValue);}
                                                                }
                                                            />
                                                        }
                                                        label="Email notification"
                                                    />
                                                </FormGroup>
                                            </Grid>

{/*                                            <Grid item xs={12} md={6}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <GreenSwitch
                                                                name="phone_notifications"
                                                                checked={form.values.phone_notifications}
                                                                onChange={(event: any, newValue: any) => {
                                                                    form.setFieldValue('phone_notifications', newValue);}
                                                                }
                                                            />
                                                        }

                                                        label="Telegram notification"
                                                    />
                                                </FormGroup>
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <GreenSwitch
                                                                name="telegram_notifications"
                                                                checked={form.values.telegram_notifications}
                                                                onChange={(event: any, newValue: any) => {
                                                                    form.setFieldValue('telegram_notifications', newValue);}
                                                                }
                                                            />
                                                        }
                                                        label="Phone notification"
                                                    />
                                                </FormGroup>
                                            </Grid>*/}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={2} mb={3} alignItems="center">
                                    <Grid item xs>
                                        <Typography color="primary" component="h2" variant="h3" className={st.settings__title}>
                                            <GroupsIcon />
                                            <span>Referrals</span>
                                        </Typography>
                                    </Grid>
{/*                                    <Grid item xs="auto">
                                        <Button color="primary" variant="text" size="small" sx={{ mr: "-28px" }}>
                                            + Invite More
                                        </Button>
                                    </Grid>*/}
                                </Grid>

                                {user?.referred_by_this_user &&
                                    <List sx={{ width: "100%" }}>
                                        {user.referred_by_this_user.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <ListItem alignItems="center" sx={{ p: 0 }} className={st.referral_item}>
 {/*                                               <ListItemAvatar>
                                                    <Avatar src="https://picsum.photos/20/30" />
                                                </ListItemAvatar>*/}
                                                <ListItemText primary={`${item.firstName} ${item.lastName}`} />
                                                <Typography>{item.status}</Typography>
                                            </ListItem>
                                            <Divider sx={{ borderBottomStyle: "dashed", my: 3 }} component="li" />
                                        </React.Fragment>
                                    ))}
                                    </List>
                                }
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <Typography color="primary" component="h2" variant="h3" className={st.settings__title}>
                                            <BarChartIcon />
                                            <span>Stat</span>
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <List sx={{ width: "100%" }}>
                                            <ListItem alignItems="center" sx={{ p: 0 }}>
                                                <ListItemText primary="Joined" />
                                                <Typography>{user?.referred_by_this_user?.length}</Typography>
                                            </ListItem>
                                            <Divider sx={{ borderBottomStyle: "dashed", my: 3 }} component="li" />
{/*                                            <ListItem alignItems="center" sx={{ p: 0 }}>
                                                <ListItemText primary="Earned" />
                                                <Typography>$4500</Typography>
                                            </ListItem>
                                            <Divider sx={{ borderBottomStyle: "dashed", mt: 3 }} component="li" />*/}
                                        </List>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography color="primary" component="h2" variant="h3" className={st.settings__title}>
                                    <LinkIcon />
                                    <span>Invite Link</span>
                                </Typography>

                                <FormControl sx={{ width: "100%" }} variant="standard">
                                    <Input
                                        inputRef={inputEl}
                                        value={user.referrerCode ? `${window.location.origin}/auth/signup?referralCode=${user.referrerCode}` : ''}
                                        fullWidth
                                        type="text"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton aria-label="wallet address copy" onClick={handleCopyClick} edge="end">
                                                    <ContentCopyIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={st.save_buttons}>
                        <Grid container spacing={4}>
                            <Grid item xs="auto">
                                <Button type="submit" color="primary" size="large" variant="contained">
                                    Update Changes
                                </Button>
                            </Grid>
                            <Grid item xs="auto">
                                <Button color="primary" size="large" variant="text"
                                    onClick={() => form.resetForm()}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/*<pre style={{color: "saddlebrown", }}>{JSON.stringify(form.values, 0, 2)}</pre>*/}
            </FormikProvider>
        </Box>
    );
});

export default Settings;
