// @ts-nocheck
import * as React from "react";
import { Field, FormikProvider, useFormik, ErrorMessage } from "formik";
import validator from "validator";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { NonAuthRoutes } from "../../../routes/routes";

import st from "../Auth.module.scss";
import { postData } from "../../../api/api";
import UIStore from "../../../store/UIStore";
import { Link, useLocation } from "react-router-dom";
import isMobilePhone from "validator/lib/isMobilePhone";
import {useState} from "react";

const SignUp: React.FC = () => {
    const routLocation = useLocation();
    const query = new URLSearchParams(routLocation.search);
    const { alertContentTime, setErrorAlertContent, setErrorAlertContentTime } = UIStore;
    const [successSignUpIsActive, setSuccessSignUpIsActive] = useState(false);

    const validate = (values: any) => {
        const errors: any = {};
        let phone = values.phoneNumber.replace(/^\+/, '');

        if (!values.firstName.trim()) {
            errors.firstName = "Required";
        }

        if (!values.lastName.trim()) {
            errors.lastName = "Required";
        }

        if (!values.email) {
            errors.email = "Required";
        }

        if (values.email && !validator.isEmail(values.email)) {
            errors.email = "Invalid email";
        }

        if (!phone) {
            errors.phoneNumber = "Required";
        }

        if (phone && !isMobilePhone(phone)) {
            errors.phoneNumber = "Invalid phone number";
        }

        if (!values.password) {
            errors.password = "Required";
        }

        if (values.password && values.password.length < 8) {
            errors.password = "At least 8 symbols";
        }

        if (values.password && values.password.length > 5 && values.confirm_password && values.confirm_password !== values.password) {
            errors.password = "Password does not match";
        }

        if (!values.confirm_password) {
            errors.confirm_password = "Required";
        }

        if (!values.terms) {
            errors.terms = "Required";
        }

        if (values.confirm_password && values.confirm_password !== values.password) {
            errors.confirm_password = "Password does not match";
        }

        return errors;
    };
    const form = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            confirm_password: "",
            phoneNumber: '',
            referrerCode: "",
            terms: false,
        },
        validate: validate,
        onSubmit: (values, { setErrors }) => {
            console.log(values, setErrors);
            setErrorAlertContent("", true);
            let sendData = {...values, phoneNumber: Number(values.phoneNumber)}
            delete sendData.terms;
            delete sendData.confirm_password;

            postData("auth/signup", sendData, "post")
                .then(() => {
                    setSuccessSignUpIsActive(true);
                })
                .catch((errorMessage) => {
                    console.log(errorMessage);
                    if (typeof errorMessage !== 'string') {
                        errorMessage = JSON.stringify(errorMessage);
                    }
                    setErrorAlertContentTime(<div dangerouslySetInnerHTML={{__html: errorMessage.replace(/\n/g, "<br />")}} />,
                        alertContentTime);
                });
        },
    });

    React.useEffect(() => {
        if (query.get("referralCode") !== null) {
            form.setFieldValue("referrerCode", query.get("referralCode"));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {successSignUpIsActive ?
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Typography component="h1" variant="h3" sx={{ m: 0 }} gutterBottom>
                            Verify your email address!
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography component="p" variant="body1">
                            New account has been created successfully! Please check your inbox and verify email address.
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Link to={NonAuthRoutes.signIn} className={st.linkFull} >
                            <Button variant="contained" fullWidth color="primary" onClick={() => setSuccessSignUpIsActive(false)} autoFocus>
                                Back to Sign in
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
                :
                <>
                    <Typography component='h1' variant='h3' mb={2}>
                        Get started for free
                    </Typography>
                    <Typography component='p' variant='subtitle1' mb={2}>
                        Create an account for yourself to check your statistics
                    </Typography>
                    <FormikProvider value={form}>
                        <Box component='form' noValidate onSubmit={form.handleSubmit} sx={{mt: 1}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Field
                                        name='firstName'
                                        children={(fieldProps: any) => (
                                            <TextField
                                                {...fieldProps.field}
                                                type='text'
                                                fullWidth
                                                label='First Name'
                                                error={form.touched.firstName && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                helperText={form.touched.firstName && !form.isValid && fieldProps.meta.error}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Field
                                        name='lastName'
                                        children={(fieldProps: any) => (
                                            <TextField
                                                {...fieldProps.field}
                                                type='text'
                                                fullWidth
                                                label='Last Name'
                                                error={form.touched.lastName && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                helperText={form.touched.lastName && !form.isValid && fieldProps.meta.error}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Field
                                        name='email'
                                        children={(fieldProps: any) => (
                                            <TextField
                                                {...fieldProps.field}
                                                type='text'
                                                fullWidth
                                                label='Email Address'
                                                error={form.touched.email && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                helperText={form.touched.email && !form.isValid && fieldProps.meta.error}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Field
                                        name='password'
                                        children={(fieldProps: any) => (
                                            <TextField
                                                {...fieldProps.field}
                                                type='password'
                                                fullWidth
                                                label='Password'
                                                error={form.touched.password && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                helperText={form.touched.password && !form.isValid && fieldProps.meta.error}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Field
                                        name='confirm_password'
                                        children={(fieldProps: any) => (
                                            <TextField
                                                {...fieldProps.field}
                                                type='password'
                                                fullWidth
                                                label='Confirm password'
                                                error={form.touched.confirm_password && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                helperText={form.touched.confirm_password && !form.isValid && fieldProps.meta.error}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Field
                                        name="phoneNumber"
                                        children={(fieldProps: any) => (
                                            <TextField
                                                {...fieldProps.field}

                                                type="text"
                                                fullWidth
                                                label="Phone number"
                                                error={form.touched.phoneNumber && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                helperText={form.touched.phoneNumber && !form.isValid && fieldProps.meta.error}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Field
                                        name='referrerCode'
                                        children={(fieldProps: any) => (
                                            <TextField
                                                {...fieldProps.field}
                                                type='text'
                                                fullWidth
                                                label='Referral code'
                                                error={form.touched.referrerCode && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                helperText={form.touched.referrerCode && !form.isValid && fieldProps.meta.error}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox value='allowExtraEmails' color='primary'/>}
                                        label='I want to receive inspiration, marketing promotions and updates via email.'
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        type='checkbox'
                                        name='terms'
                                        as={FormControlLabel}
                                        control={
                                            <Checkbox
                                                className={form.errors.terms ? st.error__checkbox : ""}
                                                checked={form.values.terms}
                                                value='terms'
                                                color='primary'
                                            />
                                        }
                                        onChange={form.handleChange}
                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                        label={
                                            <p>
                                                I accept{" "}
                                                <a className={st.terms__link} href='/#'>
                                                    Terms
                                                </a>{" "}
                                                and{" "}
                                                <a className={st.terms__link} href='/#'>
                                                    Conditions
                                                </a>
                                            </p>
                                        }
                                    />
                                    <ErrorMessage render={(msg) => <Typography color='error'>{msg}</Typography>} name='terms'/>
                                </Grid>
                            </Grid>
                            <Button type='submit' fullWidth variant='contained' sx={{mt: 3, mb: 2}}>
                                Sign Up
                            </Button>
                            <Grid container>
                                <Grid item textAlign='center' xs>
                                    <Typography variant='body2'>
                                        Do you have an account?{" "}
                                        <Link to={NonAuthRoutes.signIn} className={st.link}>
                                            {"Sign In"}
                                        </Link>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        {/*<pre style={{color: "saddlebrown", }}>{// @ts-ignore
                    JSON.stringify(form.values, 0, 2)}</pre>*/}
                    </FormikProvider></>
            }
        </>
    );
};

export default SignUp;
