import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { observer } from "mobx-react";

import "./settings/axios";
import "./assets/styles/global/index.scss";

import { NonAuthRoutes } from "./routes/routes";
// import AuthRoute from "./routes/AuthRoute";

import Auth from "./pages/Auth/Auth";
import SignIn from "./pages/Auth/SignIn/SignIn";
import SignUp from "./pages/Auth/SignUp/SignUp";
import NewPassword from "./pages/Auth/NewPassword/NewPassword";
import ForgotPassword from "./pages/Auth/ForgotPassword/ForgotPassword";
import Dashboard from "./pages/Dashboard/Dashboard";
import Performance from "./pages/Performance/Performance";
import Accounts from "./pages/Accounts/Accounts";
import Payment from "./pages/Accounts/Payment/Payment";
import Plans from "./pages/Plans/Plans";
import Connect from "./pages/Plans/Connect/Connect";
import Settings from "./pages/Settings/Settings";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import EmailConfirmation from "./pages/Auth/EmailConfirmation/EmailConfirmation";

import Layout from "./components/Layout/Layout";
import AccountsLayout from "./components/Accounts/Layout";
import Disconect from "./pages/Accounts/Disconect/Disconect";
import AuthRoute from "./routes/AuthRoute";

const App: React.FC = observer(() => {
    return (
        <Routes>
            <Route element={<AuthRoute><Layout /></AuthRoute>}>
                <Route index element={<Dashboard />} />
                <Route path={NonAuthRoutes.performance} element={<Performance />} />
                <Route path={NonAuthRoutes.accounts} element={<AccountsLayout />}>
                    <Route index element={<Accounts />} />
                    <Route path=':id' element={<Payment />} />
                    <Route path={`${NonAuthRoutes.accounts}/disconect`} element={<Disconect />} />
                </Route>
                <Route path={NonAuthRoutes.plans} element={<AccountsLayout />}>
                    <Route index element={<Plans />} />
                    <Route path=':id' element={<Connect />} />
                </Route>
                <Route path={NonAuthRoutes.settings} element={<Settings />} />
            </Route>

            <Route element={<Auth />}>
                <Route path={NonAuthRoutes.signIn} element={<SignIn />} />
                <Route path={NonAuthRoutes.signUp} element={<SignUp />} />
                <Route path={NonAuthRoutes.forgotPassword} element={<ForgotPassword />} />
                <Route path={NonAuthRoutes.newPassword} element={<NewPassword />} />
                <Route path={NonAuthRoutes.emailConfirmation} element={<EmailConfirmation/>}/>
            </Route>
            <Route path='*' element={<ErrorPage />} />
        </Routes>
    );
});

export default App;
