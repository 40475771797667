// @ts-nocheck
import * as React from "react";
import DataStore from "../../store/DataStore";
import Grid from "@mui/material/Grid";
import st from "./Dashboard.module.scss";
import TotalInfo from "../../components/TotalInfo/TotalInfo";
import { Box, Button, Card, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import ChartLayout from "../../components/Chart/Layout";

import { ReactComponent as ChartIcon } from "../../assets/images/icons/chart.svg";
import { ReactComponent as StatIcon } from "../../assets/images/icons/stat.svg";
import { useNavigate } from "react-router-dom";
import { NonAuthRoutes } from "../../routes/routes";
import PieCharts from "../../components/Chart/Pie";
import AreaCharts from "../../components/Chart/Area";
import { observer } from "mobx-react";
import UiStore from "../../store/UIStore";
// import { neatNumber } from "../../helpers/helpers";
// import NumberFormat from "react-number-format";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: "#ccc",
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: "#000",
    },
}));

// const formatNumber = (value) => {
//     return <NumberFormat value={value} decimalScale={2} defaultValue={0} thousandSeparator displayType={"text"} prefix='$' />;
// };

const Dashboard: React.FC = observer(() => {
    const navigate = useNavigate();
    const { changeHeaderButton } = UiStore;

    React.useEffect(() => {
        changeHeaderButton("plans");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleInvestment() {
        navigate(`${NonAuthRoutes.accounts}`);
    }

    return (
        <Grid component='section' container spacing={5}>
            <Grid item xs={12} md={5}>
                <Grid container spacing={6}>
                    {DataStore.user?.onboarding_completed !== null && DataStore.user.onboarding_completed !== 100 && (
                        <Grid item xs={12}>
                            <Card variant='outlined' className={st.onboarding} sx={{ border: "2px solid #000" }}>
                                <Typography color='primary' component='h2' variant='h2' className={st.onboarding__title}>
                                    Account Onboarding Steps
                                </Typography>

                                <Typography color='primary' variant='h2' className={st.onboarding__count} mt={2} mb={2}>
                                    <span>25%</span>
                                    <Typography component='span' variant='h4' className={st.onboarding__completed} sx={{ opacity: 0.6 }} ml={2}>
                                        Completed
                                    </Typography>
                                </Typography>

                                <Grid container spacing={2} alignItems='center'>
                                    <Grid item xs={12} lg>
                                        <BorderLinearProgress variant='determinate' value={25} />
                                    </Grid>

                                    <Grid item xs={12} lg='auto'>
                                        <Button onClick={handleInvestment} variant='contained'>
                                            Invest More
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={7} md={7} lg={6}>
                                <TotalInfo title='Total balance' balance='$1,428' number='-7.6%' />
                            </Grid>

                            <Grid item xs={6} sm={5} md={5} lg={4}>
                                <TotalInfo title='total profit' balance='$158.3' number='+2.4%' toUp />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} md={6} ml='auto'>
                <ChartLayout title='Traded Symbols' icon={<ChartIcon />}>
                    <Box className={st.pieChart}>
                        <PieCharts data={DataStore.userPieChart} />
                    </Box>
                </ChartLayout>
            </Grid>

            <Grid item xs={12}>
                <ChartLayout className={st.bordered} title='Account Summary' icon={<StatIcon />}>
                    <Box className={st.areaChart}>
                        <AreaCharts data={DataStore.userPortfolioProfits} />
                    </Box>
                </ChartLayout>
            </Grid>
        </Grid>
    );
});

export default Dashboard;
