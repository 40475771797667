// @ts-nocheck
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from "recharts";
import st from "./Layout.module.scss";
import React from "react";
import NumberFormat from "react-number-format";

const data = [
    {
        name: "Jan",
        value: 4000,
    },
    {
        name: "Feb",
        value: 3000,
    },
    {
        name: "Mar",
        value: 1000,
    },
    {
        name: "Apr",
        value: 500,
    },
    {
        name: "May",
        up: true,
        value: 2000,
    },
    {
        name: "Jun",
        value: 250,
    },
    {
        name: "Jul",
        up: true,
        value: 3490,
    },
    {
        name: "Aug",
        value: 490,
    },
    {
        name: "Sep",
        up: true,
        value: 3490,
    },
    {
        name: "Oct",
        value: 1490,
    },
    {
        name: "Nov",
        up: true,
        value: 3490,
    },
    {
        name: "Dec",
        value: 2490,
    },
];

const AreaCharts = () => {
    const formatNumber = (value) => {
        return (
            <NumberFormat
                value={value}
                // decimalScale={2}
                defaultValue={0}
                thousandSeparator
                displayType={"text"}
                prefix='$'
            />
        );
    };

    const CustomTooltip = (props: any) => {
        if (props.active && props.payload && props.payload.length) {
            return (
                <div className={st.customTooltip}>
                    {props.payload[0].payload.up ? (
                        <svg width='12' height='12' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M10.1662 6.99906V23.3751H13.5829V6.99906L20.9167 14.3329L23.3323 11.9174L11.8745 0.459564L0.416748 11.9174L2.83233 14.3329L10.1662 6.99906Z'
                                fill='#fff'
                            />
                        </svg>
                    ) : (
                        <svg width='12' height='12' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M10.166 17.0015V0.625481H13.5827V17.0015L20.9165 9.66768L23.3321 12.0832L11.8743 23.541L0.416504 12.0832L2.83209 9.66768L10.166 17.0015Z'
                                fill='#fff'
                            />
                        </svg>
                    )}
                    {formatNumber(props.payload[0].value)}
                </div>
            );
        }

        return null;
    };

    const customLegend = (props: any) => {
        const { payload } = props;

        return (
            <ul>
                {payload.map((entry: any, index: number) => (
                    <li key={`item-${index}`} className={st.legend}>
                        <svg width='8' height='8' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <circle cx='8' cy='8' r='7.5' fill={entry.color} />
                        </svg>
                        <span style={{ color: entry.color }}>{entry.value}</span>
                    </li>
                ))}
            </ul>
        );
    };
    const CustomizedDot = (props) => {
        const { cx, cy } = props;
        return (
            <svg x={cx - 4} y={cy - 4} width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <circle cx='4' cy='4' r='4' fill='black' stroke='black' />
            </svg>
        );
    };

    return (
        <ResponsiveContainer width='100%' height='100%'>
            <AreaChart
                data={data}
                margin={{
                    top: 10,
                    right: 20,
                    left: 0,
                    bottom: 0,
                }}
            >
                <CartesianGrid vertical={false} stroke='#000' strokeOpacity='0.2' />
                <XAxis dataKey='name' />
                <YAxis axisLine={false} />
                <Tooltip content={<CustomTooltip />} />
                <Legend align='left' verticalAlign='top' iconType='cirlce' content={customLegend} />
                <Area name='Return value' dataKey='value' stroke='#000' fill='#000' fillOpacity='0.2' dot={<CustomizedDot />} />
            </AreaChart>
        </ResponsiveContainer>
    );
};

export default AreaCharts;
