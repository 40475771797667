// @ts-nocheck
import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Header from "../Header/Header";
import st from "./Layout.module.scss";
import classNames from "classnames";
import { ReactComponent as InfoIcon } from "../../assets/images/icons/info.svg";
import { ReactComponent as CrossIcon } from "../../assets/images/icons/cross.svg";
import React from "react";
import { observer } from "mobx-react";
import UIStore from "../../store/UIStore";
import DataStore from "../../store/DataStore";
import { getData } from "../../api/api";


const Layout: React.FC = observer(() => {
    const { successAlertContent, errorAlertContent, setSuccessAlertContent, setErrorAlertContent } = UIStore;

    React.useEffect( () => {
        let isMounted = true;

        if (DataStore.user.id && DataStore.needDataUpdate) {
            DataStore.updateNeedDataUpdate(false);

            if (0) {
                getData(`users/${DataStore.user.id}/info`)
                    .then(({ data }) => {
                        if (isMounted) {
                            DataStore.updateUser(data);
                        }
                    })
                    .catch((error) => {console.log(error);});
            }

/*            getData(`users/${DataStore.user.id}/info/avatar`)
                .then(({ data }) => {
                    console.log('data', data)
                    // DataStore.updateUser(data);
                })
                .catch((error) => {console.log(error);});*/
        }

        return () => {
            isMounted = false
        };
        // eslint-disable-next-line
    }, [DataStore.user.id]);

    return (
        <>
            <Header />

            <Box component='main' className={st.layout__container}>
                <Container maxWidth='lg' className='relative'>
                    {(successAlertContent || errorAlertContent) && (
                        <div className={classNames("alert", errorAlertContent ? "alert_error" : "")}>
                            <div className={"alert__info"}>
                                <InfoIcon />
                                <div>{successAlertContent || errorAlertContent}</div>
                            </div>

                            <CrossIcon
                                className={classNames("hover-stroke hover-stroke-deep", "alert__close")}
                                onClick={() => {
                                    successAlertContent ? setSuccessAlertContent("") : setErrorAlertContent("");
                                }}
                            />
                        </div>
                    )}

                    <Outlet />
                </Container>
            </Box>
        </>
    );
});

export default Layout;
