import React from "react";
import {Link, Outlet} from "react-router-dom";
import UIStore from "../../store/UIStore";
import classNames from "classnames";
import st from "./Auth.module.scss";
import { ReactComponent as InfoIcon } from "../../assets/images/icons/info.svg";
import { ReactComponent as CrossIcon } from "../../assets/images/icons/cross.svg";
import { observer } from "mobx-react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {NonAuthRoutes} from "../../routes/routes";
import logo from "../../assets/images/logo.svg";
import sphere from "../../assets/images/sphere.png";

const Auth: React.FC = observer(() => {
    const { successAlertContent, errorAlertContent, setSuccessAlertContent, setErrorAlertContent } = UIStore;

    return (
        <div className={st.auth}>
            <div className={st.auth_container}>
                {(successAlertContent || errorAlertContent) && (
                    <div className={classNames("alert", errorAlertContent ? "alert_error" : "")}>
                        <div className={"alert__info"}>
                            <InfoIcon />
                            <div>{successAlertContent || errorAlertContent}</div>
                        </div>

                        <CrossIcon
                            className={classNames("hover-stroke hover-stroke-deep", "alert__close")}
                            onClick={() => {
                                successAlertContent ? setSuccessAlertContent("") : setErrorAlertContent("");
                            }}
                        />
                    </div>
                )}

                <Grid container component='main' sx={{ height: "100vh" }}>
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        md={5}
                        sx={{
                            position: "relative",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Box
                            sx={{
                                pb: { xs: 2, md: 5 },
                                mx: { xs: 3, md: 10, lg: 20 },
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <div className={st.logo}>
                                <Link to={NonAuthRoutes.home}>
                                    <img src={logo} alt='Logo' />
                                </Link>
                            </div>

                            <Outlet />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={false}
                        sm={4}
                        md={7}
                        sx={{
                            borderLeft: "1px solid #8798AD",
                            backgroundImage: `url(${sphere})`,
                            backgroundRepeat: "no-repeat",
                            backgroundColor: (t) => (t.palette.mode === "light" ? "#fff" : t.palette.grey[900]),
                            backgroundSize: "610px",
                            backgroundPosition: "center",
                        }}
                    />
                </Grid>
            </div>
        </div>
    );
});

export default Auth;
