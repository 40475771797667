import React from "react";
import Box from "@mui/material/Box";
import st from "./Layout.module.scss";
import { SvgIconProps, Typography } from "@mui/material";
import classNames from "classnames";

type props = {
    title: string;
    className?: string;
    icon?: React.ReactNode;
    exchanges?: boolean;
    children: React.ReactNode;
} & SvgIconProps;

const ChartLayout: React.FC<props> = ({ icon, title, className, children }) => {
    return (
        <Box component='section' className={classNames(st.layout__container, className)}>
            <div className={st.layout__top}>
                <Box className={st.layout__top_chart}>
                    {icon}
                    <Typography variant='h3'>{title}</Typography>
                </Box>
            </div>

            {children}
        </Box>
    );
};

export default ChartLayout;
