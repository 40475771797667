import Box from "@mui/material/Box";
import st from "./Table.module.scss";
import { Typography } from "@mui/material";
import classNames from "classnames";

type props = {
    title: string;
    className?: string;
    icon?: React.ReactNode;
    children: React.ReactNode;
};

const TableLayout: React.FC<props> = ({icon, title, className, children}) => {
    return (
        <Box component="section" className={classNames(st.layout__container, className)}>
            <Box className={st.layout__top}>
                {icon}
                <Typography variant="h3">{title}</Typography>
            </Box>
            {children}
        </Box>
    );
};

export default TableLayout;
