import {Box, Button, Grid, TextField, Typography, ToggleButtonGroup, ToggleButton, TableContainer} from "@mui/material";
import React from "react";
import st from "./Accounts.module.scss";
import stTable from "../../components/Table/Table.module.scss";
import { ReactComponent as TableIcon } from "../../assets/images/icons/table.svg";
import { ReactComponent as RemoveIcon } from "../../assets/images/icons/circle-cross.svg";
import TableLayout from "../../components/Table/TableLayout";
import UiStore from "../../store/UIStore";
import { useNavigate } from "react-router-dom";
import { NonAuthRoutes } from "src/routes/routes";
import classNames from "classnames";

// const opt1 = [
//     { label: "Select your broker", value: 0 },
//     { label: "I", value: 1 },
//     { label: "II", value: 2 },
// ];

// const opt2 = [
//     { label: "Select your broker’s server ", value: 0 },
//     { label: "I", value: 1 },
//     { label: "II", value: 2 },
// ];

const Accounts: React.FC = () => {
    const [type, setType] = React.useState("demo");
    const [version, setVersion] = React.useState("mt4");
    const { changeHeaderButton } = UiStore;
    const navigate = useNavigate();

    const handleChangeType = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
        setType(newAlignment);
    };

    const handleChangeVersion = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
        setVersion(newAlignment);
    };

    React.useEffect(() => {
        changeHeaderButton("accountsInner");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box component='section' className={st.deposit__container}>
            <Grid container spacing={{ xs: 4, lg: 2 }}>
                <Grid item xs={12}>
                    <Box className={st.box__item}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={12} md={9} lg={10}>
                                <Typography variant='h2' className={st.deposit__title} mb={4}>
                                    MetaTrader Connect
                                </Typography>
                                <Typography mb={4}>
                                    In order for our platform to be able to trade on behalf of your account, we need to connect with your MetaTrader
                                    account. Please introduce your account details and let’s get started 🚀.
                                </Typography>
                                <Grid container spacing={4} mb={{ xs: 0, md: 4 }}>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant='body2' fontWeight={500} mb={1}>
                                            Account type
                                        </Typography>
                                        <ToggleButtonGroup
                                            fullWidth
                                            sx={{ gap: 4 }}
                                            value={type}
                                            exclusive
                                            onChange={handleChangeType}
                                            aria-label='type'
                                        >
                                            <ToggleButton sx={{ width: "100%" }} value='demo'>
                                                Demo
                                            </ToggleButton>
                                            <ToggleButton sx={{ width: "100%", borderLeftColor: "rgba(0, 0, 0, 0.12) !important" }} value='live'>
                                                Live
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant='body2' fontWeight={500} mb={1}>
                                            METATRADER VERSION
                                        </Typography>
                                        <ToggleButtonGroup
                                            fullWidth
                                            sx={{ gap: 4 }}
                                            value={version}
                                            exclusive
                                            onChange={handleChangeVersion}
                                            aria-label='VERSION'
                                        >
                                            <ToggleButton sx={{ width: "100%" }} value='mt4'>
                                                MT4
                                            </ToggleButton>
                                            <ToggleButton sx={{ width: "100%", borderLeftColor: "rgba(0, 0, 0, 0.12) !important" }} value='mts'>
                                                MT5
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='body2' fontWeight={500} mb={1}>
                                            BROKER
                                        </Typography>
                                        <TextField name='text' type='text' fullWidth label='Your Broker Name' />
                                        {/*<Autocomplete
                                            options={opt1}
                                            sx={{ width: "100%" }}
                                            renderInput={(params) => <TextField {...params} label='Select your account' />}
                                        />*/}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='body2' fontWeight={500} mb={1}>
                                            BROKER SERVER
                                        </Typography>
                                        <TextField name='text' type='text' fullWidth label='Your Broker Server' />
                                        {/*<Autocomplete
                                            options={opt2}
                                            sx={{ width: "100%" }}
                                            renderInput={(params) => <TextField {...params} label='Select your account' />}
                                        />*/}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant='body2' fontWeight={500} mb={1}>
                                            Login
                                        </Typography>
                                        <TextField name='text' type='text' fullWidth label='Login' />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant='body2' fontWeight={500} mb={1}>
                                            Password
                                        </Typography>
                                        <TextField name='password' type='password' fullWidth label='Password' />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Button variant='contained' fullWidth onClick={() => {
                                            // remove after integrate
                                            navigate(`${NonAuthRoutes.accounts}/disconect`);
                                        }}>
                                            Connect Account
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={3} lg={2}>
                                <div className={st.box__text}>
                                    ⚡All these details are necessary for us to be able to trade on your behalf. Using these details, we are not able
                                    to withdraw or take the money out from your account. ⚠️ Always be aware that FOREX is an high risk investment and
                                    we do not advise you to use more than you can lose. Do not use borrowed money or money that put a pressure on
                                    yourself. 💠 Do not share these details with other platforms or split the account.
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12} mt={5}>
                    <TableLayout className={st.bordered} title='Connected Accounts' icon={<TableIcon />}>
                        <TableContainer>
                            <div aria-label="caption table" className={stTable.table}>
                                <div className={stTable.head}>
                                    <div className={stTable.th}>Account</div>
                                    <div className={stTable.th}></div>
                                    <div className={stTable.th}>Balance</div>
                                    <div className={stTable.th}>Starting Date</div>
                                    <div className={stTable.th}>Day</div>
                                    <div className={stTable.th}>Month</div>
                                    <div className={stTable.th}>Total</div>
                                </div>

                                <div className={stTable.body}>
                                    {/*{data.map((row, index) => (*/}
                                        <div className={stTable.tr}>
                                            <div className={stTable.td}>Cos MT4 100k</div>
                                            <div className={classNames(stTable.td, stTable.td_cross)}><RemoveIcon /></div>
                                            <div className={stTable.td}>$128,2812.92</div>
                                            <div className={stTable.td}>15/02/2022</div>
                                            <div className={stTable.td}>25,30</div>
                                            <div className={stTable.td}>321,40</div>
                                            <div className={stTable.td}>12,811</div>
                                        </div>
                                        <div className={stTable.tr}>
                                            <div className={stTable.td}>Cos MT4 100k</div>
                                            <div className={classNames(stTable.td, stTable.td_cross)}><RemoveIcon /></div>
                                            <div className={stTable.td}>$128,2812.92</div>
                                            <div className={stTable.td}>15/02/2022</div>
                                            <div className={stTable.td}>25,30</div>
                                            <div className={stTable.td}>-12,4%</div>
                                            <div className={stTable.td}>-12,4%</div>
                                        </div>
                                        <div className={stTable.tr}>
                                            <div className={stTable.td}>Cos MT4 100k</div>
                                            <div className={classNames(stTable.td, stTable.td_cross)}><RemoveIcon /></div>
                                            <div className={stTable.td}>$128,2812.92</div>
                                            <div className={stTable.td}>15/02/2022</div>
                                            <div className={stTable.td}>25,30</div>
                                            <div className={stTable.td}>-12,4%</div>
                                            <div className={stTable.td}>-12,4%</div>
                                        </div>
                                        <div className={stTable.tr}>
                                            <div className={stTable.td}>Cos MT4 100k</div>
                                            <div className={classNames(stTable.td, stTable.td_cross)}><RemoveIcon /></div>
                                            <div className={stTable.td}>$128,2812.92</div>
                                            <div className={stTable.td}>15/02/2022</div>
                                            <div className={stTable.td}>25,30</div>
                                            <div className={stTable.td}>-12,4%</div>
                                            <div className={stTable.td}>-12,4%</div>
                                        </div>
                                        <div className={stTable.tr}>
                                            <div className={stTable.td}>Cos MT4 100k</div>
                                            <div className={classNames(stTable.td, stTable.td_cross)}><RemoveIcon /></div>
                                            <div className={stTable.td}>$128,2812.92</div>
                                            <div className={stTable.td}>15/02/2022</div>
                                            <div className={stTable.td}>25,30</div>
                                            <div className={stTable.td}>-12,4%</div>
                                            <div className={stTable.td}>-12,4%</div>
                                        </div>
                                    {/*))}*/}
                                </div>
                            </div>
                        </TableContainer>
                    </TableLayout>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Accounts;
