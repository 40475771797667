import axios from "axios";

class mediaObjectApi {
    loadMedia = (file, userId) => {
        let formData = new FormData();
        formData.append('file', file);

        return axios({
            method: 'PUT',
            url: `${ axios.defaults.baseURL }/users/${ userId }/info/avatar`,
            // data: file,
            data: formData,
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'Content-Type': 'image/png',
                'jwt': localStorage.getItem(process.env.REACT_APP_AT_NAME),
            },
        });
    }

    deleteMedia = (iri) => {
        return axios({
            method: 'DELETE',
            url: axios.defaults.baseURL + iri,
            headers: {
                'accept': '*/*',
            },
        });
    }

    deleteAvatar = (id) => {
        return axios({
            method: 'DELETE',
            url: axios.defaults.baseURL + `api/profiles/${ id }/delete-avatar`,
            headers: {
                'accept': '*/*',
            },
        });
    }

}

const mediaObject = new mediaObjectApi();

export default mediaObject;
