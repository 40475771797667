// @ts-nocheck
import {Box, Grid, TableContainer} from "@mui/material";
import style from "./Plans.module.scss";
import st from "../Performance/Performance.module.scss";
import stTable from "../../components/Table/Table.module.scss";
import ChartLayout from "../../components/Chart/Layout";
import { ReactComponent as DollarIcon } from "../../assets/images/icons/dollar.svg";
import { ReactComponent as TableIcon } from "../../assets/images/icons/table.svg";
import TableLayout from "../../components/Table/TableLayout";
import React from "react";
import BarCharts from "../../components/Chart/Bar";
import { observer } from "mobx-react";
import UIStore from "../../store/UIStore";
import classNames from "classnames";

const Performance: React.FC = observer(() => {
    const { changeHeaderButton } = UIStore;

    React.useEffect(() => {
        changeHeaderButton("plansInner");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid component='section' container spacing={6}>
            <Grid item xs={12}>
                <div className={style.box__item}>
                    <div className={style.box__title}>Choose Plan</div>
                    <div className={style.box__text}>
                        In order for our platform to be able to trade on behalf of your account, we need to connect with your MetaTrader account.
                        Please introduce your account details and let’s get started 🚀.
                    </div>
                </div>
            </Grid>

            <Grid item xs={12} pt={10}>
                <TableLayout className={st.bordered} title='Available Plans' icon={<TableIcon />}>
                    <TableContainer>
                        <div aria-label="caption table" className={stTable.table}>
                            <div className={stTable.head}>
                                <div className={stTable.th}>Name</div>
                                <div className={stTable.th}>Won</div>
                                <div className={stTable.th}>Daily</div>
                                <div className={stTable.th}>Monthly</div>
                                <div className={stTable.th}>Fee</div>
                                <div className={stTable.th}>Available spots</div>
                                <div className={stTable.th}>Actions</div>
                            </div>

                            <div className={stTable.body}>
                                {/*{data.map((row, index) => (*/}
                                <div className={stTable.tr}>
                                    <div className={stTable.td}>Plan #1</div>
                                    <div className={stTable.td}>70%</div>
                                    <div className={stTable.td}>1.05%</div>
                                    <div className={stTable.td}>36.84%</div>
                                    <div className={stTable.td}>30%</div>
                                    <div className={stTable.td}>27/30</div>
                                    <div className={classNames(stTable.td, stTable.td_border)}><div>Connect</div></div>
                                </div>
                                <div className={stTable.tr}>
                                    <div className={stTable.td}>Plan #2</div>
                                    <div className={stTable.td}>70%</div>
                                    <div className={stTable.td}>1.05%</div>
                                    <div className={stTable.td}>36.84%</div>
                                    <div className={stTable.td}>30%</div>
                                    <div className={stTable.td}>27/30</div>
                                    <div className={classNames(stTable.td, stTable.td_border)}><div>Connect</div></div>
                                </div>
                                <div className={stTable.tr}>
                                    <div className={stTable.td}>Plan #3</div>
                                    <div className={stTable.td}>70%</div>
                                    <div className={stTable.td}>1.05%</div>
                                    <div className={stTable.td}>36.84%</div>
                                    <div className={stTable.td}>30%</div>
                                    <div className={stTable.td}>27/30</div>
                                    <div className={classNames(stTable.td, stTable.td_border)}><div>Connect</div></div>
                                </div>
                                <div className={stTable.tr}>
                                    <div className={stTable.td}>Plan #4</div>
                                    <div className={stTable.td}>70%</div>
                                    <div className={stTable.td}>1.05%</div>
                                    <div className={stTable.td}>36.84%</div>
                                    <div className={stTable.td}>30%</div>
                                    <div className={stTable.td}>27/30</div>
                                    <div className={classNames(stTable.td, stTable.td_border)}><div>Connect</div></div>
                                </div>
                                {/*))}*/}
                            </div>
                        </div>
                    </TableContainer>
                </TableLayout>
            </Grid>

            <Grid item xs={12} pt={10}>
                <ChartLayout className={st.bordered} title='Plan #1 Performance' icon={<DollarIcon />} exchanges>
                    <Box className={st.barChart}>
                        {/* <BarCharts data={DataStore.userMonthlyProfits} /> */}
                        <BarCharts />
                    </Box>
                </ChartLayout>
            </Grid>
        </Grid>
    );
});

export default Performance;
