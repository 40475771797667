// @ts-nocheck
import React from "react";
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from "@mui/material";
import { PieChart, Pie, ResponsiveContainer, Cell } from "recharts";
import st from "./Layout.module.scss";

const data = [
    { name: "SOL", label: "+23.4%", value: 300, fill: "#F7F7F7", fillOpacity: "1" },
    { name: "USDT", label: "+23.4%", value: 300, fill: "#CFDBE4", fillOpacity: "1" },
    { name: "ADA", label: "+23.4%", value: 300, fill: "#D2FE83", fillOpacity: "1" },
];

const PieCharts = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const renderActiveShape = (props: any) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, outerRadius, fill, name, label } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? "start" : "end";
        const textX = ex + (cos >= 0 ? 1 : -1) * 12;

        return (
            <g>
                <rect x={textX > cx ? textX - 10 : textX - 80} y={ey - 25} width="90" height="60" fill="#000" strokeWidth="2" />
                <rect x={textX > cx ? textX - 5 : textX - 60} y={ey - 10} width="8" height="8" fill={fill} />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <text x={textX > cx ? textX + 10 : textX - 5} y={ey} textAnchor={textAnchor} fill="#fff">
                    {name}
                </text>
                <text x={textX > cx ? textX + 10 : textX - 5} y={ey} dy={18} textAnchor={textAnchor} fill="#fff">
                    {label}
                </text>
            </g>
        );
    };

    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart className={st.pie_container}>
                <Pie data={data} dataKey="value" label={renderActiveShape} cx="50%" cy="50%" innerRadius={matches ? 120 : 40} outerRadius={matches ? 170 : 70}>
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.fill} fillOpacity={entry.fillOpacity} />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
};

export default PieCharts;
