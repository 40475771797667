// @ts-nocheck
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import st from "./Layout.module.scss";
import React from "react";
import { observer } from "mobx-react";

const datas = [
    {
        name: "Jan",
        value: 2400,
        value2: 4000,
    },
    {
        name: "Feb",
        value: 1398,
        value2: 3000,
    },
    {
        name: "Mar",
        value: 2000,
        value2: 9800,
    },
    {
        name: "Apr",
        value: 2780,
        value2: 3908,
    },
    {
        name: "May",
        value: 1890,
        value2: 4800,
    },
    {
        name: "Jun",
        value: 2390,
        value2: 3800,
    },
    {
        name: "Jul",
        value: 3490,
        value2: 4300,
    },
    {
        name: "Aug",
        value: 490,
        value2: 2400,
    },
    {
        name: "Sep",
        value: 1398,
        value2: 3490,
    },
    {
        name: "Oct",
        value: 1490,
        value2: 9800,
    },
    {
        name: "Nov",
        value: 3490,
        value2: 4800,
    },
    {
        name: "Dec",
        value: 2490,
        value2: 4300,
    },
];

const BarCharts = observer(({ data = [] }) => {
    const CustomTooltip = (props: any) => {
        const { payload } = props;

        if (props.active && payload && payload.length) {
            return payload.map((entry: any, index: number) => (
                <div key={`item-${index}`} className={st.customTooltip}>
                    {`${entry.name}: $${entry.value}`}
                </div>
            ));
        }

        return null;
    };

    const customLegend = (props: any) => {
        const { payload } = props;

        return (
            <ul>
                {payload.map((entry: any, index: number) => (
                    <li key={`item-${index}`} className={st.legend}>
                        <svg width='8' height='8' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <circle cx="8" cy="8" r="7.5" fill={entry.color} />
                        </svg>
                        <span>{entry.value}</span>
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <ResponsiveContainer width='100%' height='100%'>
            <BarChart
                width={500}
                height={300}
                data={datas}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid vertical={false} stroke='#000' strokeOpacity='0.2' />
                <XAxis dataKey='name' />
                <YAxis axisLine={false} />
                <Tooltip content={<CustomTooltip />} />
                <Legend align='left' verticalAlign='top' iconType='cirlce' content={customLegend} />
                <Bar name='Balance' maxBarSize={10} dataKey='value' fill='#000' />
                <Bar name='Balance with Profit' maxBarSize={10} dataKey='value2' fill='#d2fe83' />
            </BarChart>
        </ResponsiveContainer>
    );
});

export default BarCharts;
