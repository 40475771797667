// @ts-nocheck
import * as React from "react";
import { Field, FormikProvider, useFormik } from "formik";
import validator from "validator";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { NonAuthRoutes } from "../../../routes/routes";
import { postData } from "../../../api/api";
import { Link } from "react-router-dom";

import st from "../Auth.module.scss";
import { useNavigate } from "react-router-dom";
import UIStore from "../../../store/UIStore";
import DataStore from "../../../store/DataStore";
import jwt_decode from "jwt-decode";

const SignIn: React.FC = () => {
    let navigate = useNavigate();
    const { alertContentTime, setErrorAlertContent, setErrorAlertContentTime } = UIStore;

    const validate = (values: any) => {
        const errors: any = {};

        if (!values.email) {
            errors.email = "Required";
        }

        if (values.email && !validator.isEmail(values.email)) {
            errors.email = "Invalid email";
        }

        if (!values.password) {
            errors.password = "Required";
        }

        if (values.password && values.password.length < 6) {
            errors.password = "At least 6 symbols";
        }

        return errors;
    };

    const form = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validate: validate,
        onSubmit: (values, { setErrors }) => {
            setErrorAlertContent("", true);
            postData("auth/sign_in_with_email_password", values, "post")
                .then(({ data }) => {
                    console.log("data", data);
                    if (data.accessToken) {
                        DataStore.updateAuthorize(true);
                        DataStore.updateNeedDataUpdate(true);
                        localStorage.setItem(process.env.REACT_APP_AT_NAME, data.accessToken);
                        localStorage.setItem(process.env.REACT_APP_RT_NAME, data.refreshToken);
                        const {userId} = jwt_decode(data.accessToken);
                        DataStore.updateUser({id: userId});
                        navigate("/", { replace: true });
                    } else {
                        console.log("data");
                    }
                })
                .catch((errorMessage) => {
                    console.log("errorMessage", errorMessage);
                    setErrorAlertContentTime(JSON.stringify(errorMessage), alertContentTime);
                });
        },
    });

    return (
        <>
            <Typography component='h1' variant='h3' mb={2}>
                Sign in
            </Typography>

            <Typography component='p' variant='subtitle1' mb={2}>
                Sign in into your account to check your stats
            </Typography>

            <FormikProvider value={form}>
                <Box component='form' noValidate onSubmit={form.handleSubmit} sx={{mt: 1}} className={st.form}>
                    <Field
                        name='email'
                        children={(fieldProps: any) => (
                            <TextField
                                {...fieldProps.field}
                                type='text'
                                fullWidth
                                label='Email Address'
                                error={form.touched.email && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                helperText={form.touched.email && !form.isValid && fieldProps.meta.error}
                            />
                        )}
                    />

                    <Field
                        name='password'
                        children={(fieldProps: any) => (
                            <TextField
                                {...fieldProps.field}
                                type='password'
                                fullWidth
                                label='Password'
                                error={form.touched.password && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                helperText={form.touched.password && !form.isValid && fieldProps.meta.error}
                            />
                        )}
                    />

                    <FormControlLabel control={<Checkbox value='remember' color='primary'/>} label='Remember me'/>

                    <Button type='submit' fullWidth variant='contained' sx={{mt: 3, mb: 2}}>
                        Sign In
                    </Button>

                    <Grid container spacing={2}>
                        <Grid item textAlign='center' xs={12}>
                            <Typography variant='body2'>
                                Don't have an account?{" "}
                                <Link to={NonAuthRoutes.signUp} className={st.link}>
                                    {"Sign Up"}
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item textAlign='center' xs={12}>
                            <Typography variant='body2'>
                                <Link to={NonAuthRoutes.forgotPassword} className={st.link}>
                                    {"Forgot Password?"}
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                {/*<pre style={{color: "saddlebrown", }}>{JSON.stringify(form.values, 0, 2)}</pre>*/}
            </FormikProvider>
        </>
    );
};

export default SignIn;
