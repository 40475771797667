import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Field, FormikProvider, useFormik } from "formik";
import { NonAuthRoutes } from "../../../routes/routes";
import { Link } from "react-router-dom";
import st from "../Auth.module.scss";
import validator from "validator";
import UIStore from "../../../store/UIStore";
import { postData } from "../../../api/api";

const ForgotPassword: React.FC = () => {
    const { alertContentTime, setErrorAlertContent, setErrorAlertContentTime, setSuccessAlertContent } = UIStore;

    const validate = (values: any) => {
        const errors: any = {};

        if (!values.email) {
            errors.email = "Required";
        }

        if (values.email && !validator.isEmail(values.email)) {
            errors.email = "Invalid email";
        }

        return errors;
    };

    const form = useFormik({
        initialValues: {
            email: "",
        },
        validateOnBlur: false,
        validateOnChange: false,
        validate: validate,
        onSubmit: (values) => {
            setErrorAlertContent("", true);
            setSuccessAlertContent("If this email exists we will send a reset instruction to it");
            postData("forgot_password", values, "post").catch((errorMessage: any) => {
                setErrorAlertContentTime(errorMessage, alertContentTime);
            });
        },
    });

    return (
        <>
            <Typography component='h1' variant='h3' mb={2}>
                Forgot Password
            </Typography>
            <Typography component='p' variant='subtitle1' mb={2}>
                Did you forget your password?
            </Typography>
            <FormikProvider value={form}>
                <Box component='form' noValidate onSubmit={form.handleSubmit} sx={{ mt: 1, width: "100%" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Field
                                name='email'
                                children={(fieldProps: any) => (
                                    <TextField
                                        {...fieldProps.field}
                                        type='text'
                                        fullWidth
                                        label='Email Address'
                                        error={form.touched.email && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                        helperText={form.touched.email && !form.isValid && fieldProps.meta.error}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
                        Reset Password
                    </Button>
                    <Grid container>
                        <Grid item textAlign='center' xs>
                            <Typography variant='body2'>
                                Do you remember it?{" "}
                                <Link to={NonAuthRoutes.signIn} className={st.link}>
                                    {"Sign In"}
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                {/* <pre style={{color: "saddlebrown", }}>{// @ts-ignore
                    JSON.stringify(form.values, 0, 2)}</pre> */}
            </FormikProvider>
        </>
    );
};

export default ForgotPassword;
