import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import st from "./Disconect.module.scss";
import UiStore from "../../../store/UIStore";

const Disconect: React.FC = () => {
    const { changeHeaderButton } = UiStore;

    React.useEffect(() => {
        changeHeaderButton("accounts");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid component='section' container spacing={6}>
            <Grid item xs={12}>
                <Box className={st.box__item}>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={12}>
                            <div className={st.box__title}>Account Disconnect</div>
                            <div className={st.box__text}>
                                We are sorry that you want to disconnect your account. As soon as you disconnect the account, the open trades will
                                stay active and make sure you monitor the trades with attention. Make sure that your invoice has been paid and you
                                have no debts to the platform.
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body2' fontWeight={500} mb={1}>
                                Account
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='h5' fontWeight={500} mb={1}>
                                Cos MT4 100k
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant='outlined' fullWidth>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant='contained' fullWidth>
                                Disconnect Account
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container spacing={2} alignItems='center'>
                    <Grid item xs={4}>
                        <Typography variant='h4'>Status:</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <g strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
                                <path
                                    d='M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z'
                                    fill='#FF0000'
                                    stroke='#FF0000'
                                />
                                <path d='M18.75 11.25L11.25 18.75' stroke='white' />
                                <path d='M11.25 11.25L18.75 18.75' stroke='white' />
                            </g>
                        </svg>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant='h4'>Account Disconnected</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Disconect;
